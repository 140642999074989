import React, { useState, useRef, useEffect, createRef } from "react";
import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";
import Swal from 'sweetalert2'
import ReCAPTCHA from "react-google-recaptcha";


// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";

export default function Contact() {

  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [status, setStatus] = useState(null);
  const [alertPrompt, setAlertPrompt] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");

  const refCaptcha = createRef();

  const Swal = require('sweetalert2');

  useEffect(() => emailjs.init("CVzKOW7yAfyvTJba8"), []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = refCaptcha.current.getValue();
    setStatus(true);


    const form = document.getElementById('form');
    var fname_str = " "
    var email_str = " "
    var subject_str = " "
    var message_str = " "


    if (document.getElementById('fname') != null) {
      fname_str = document.getElementById("fname").value;
    }

    if (document.getElementById('email') != null) {
      email_str = document.getElementById("email").value;
    }

    if (document.getElementById('subject') != null) {
      subject_str = document.getElementById("subject").value;
    }

    if (document.getElementById('message') != null) {
      message_str = document.getElementById("message").value;
    }

    const params = {
      fname: fname_str,
      email: email_str,
      subject: subject_str,
      message: message_str,
      "g-recaptcha-response": token,
    };

    // Function to show custom alert
    function showCustomAlert(message) {
      const alertDiv = document.createElement('div');
      alertDiv.className = 'custom-alert';
      alertDiv.innerHTML = `
      <p>${message}</p>
      <button onclick="this.parentElement.style.display='none'">OK</button>
  `;
      document.body.appendChild(alertDiv);
      setTimeout(() => alertDiv.style.display = 'none', 5000); // Hide after 5 seconds
      console.log("Custom alert shown:", message);
    }

    let captcha;

    const onChange = (value) => {
      console.log(value);
    }

    const setCaptchaRef = (ref) => {
      if (ref) {
        return captcha = ref;
      }
    };

    const resetCaptcha = () => {
      // maybe set it till after is submitted
      captcha.reset();
    }

    switch (true) {
      case fname_str === "":
        Swal.fire({
          title: 'First Name cannot be Blank!',
          text: 'Kindly please enter the first name',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        setAlertMessage("Name cannot be Blank");
        setStatus(false);

        break;

      case email_str === "":
        Swal.fire({
          title: 'Email address cannot be Blank!!',
          text: 'Kindly please enter the email address',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        setAlertMessage("Email Address cannot be Blank");
        setStatus(false);

        break;

      case subject_str === "":
        Swal.fire({
          title: 'Subject cannot be Blank!!',
          text: 'Kindly please enter the message',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        setAlertMessage("Email Address cannot be Blank");
        setStatus(false);

      case message_str === "":
        Swal.fire({
          title: 'Message cannot be Blank!!',
          text: 'Kindly please enter the message',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        setAlertMessage("Email Address cannot be Blank");
        setStatus(false);

        break;

      case token === "":
        Swal.fire({
          title: 'Captcha is not clicked!!',
          text: 'Kindly please select the captcha',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        setAlertMessage("Email Address cannot be Blank");
        setStatus(false);

        break;

      case token === undefined:
        Swal.fire({
          title: 'Recaptcha error!',
          text: 'Sorry for the inconvenience, There is an error at the Recaptcha service, We will look into it',
          icon: 'error',
          confirmButtonText: 'Ok'
        })
        setAlertMessage("Recaptche Error... ");
        setStatus(false);

        break;

      default:

        emailjs
          .send(
            "service_7arzguj",
            "template_mb63917",
            params
          )
          .then(function (response) {
            document.getElementById("fname").value = "";
            document.getElementById("email").value = "";
            document.getElementById("subject").value = "";
            document.getElementById("message").value = "";
            console.log(response);
            console.log('Success', response.status, response.text);
            // alert('Thank you, ' + params.sendername + '! Your message has been sent.');
            // alert("Your message sent successfully!!");
            // alert("Inquiry Sent !, Thank you for visiting us.");
            // showCustomAlert("Email sent successfully!");
            Swal.fire({
              title: 'success!',
              text: 'Thank You for Contacting! We will get back to you soon!',
              icon: 'success',
              confirmButtonText: 'Ok'
            })
            window.grecaptcha.reset();
            // alert("Thank You for Contacting! I'll get back to you soon!");

          })
          .catch((err) => {
            toast.error("Invalid Email or Server Error");
          });
    }
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font18">
              Would you like to know more about Manjavo or ask us a question? We are looking forward to hearing from you and will be pleased to help you.
            </p>


          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form>
                <label className="font15">First name:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" />
                <label className="font15">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" />
                <label className="font15">Subject:</label>
                <input type="text" id="subject" name="subject" className="font20 extraBold" />
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" />
              </Form>
              <SumbitWrapper className="flex">
                <ReCAPTCHA
                  sitekey={'6LcvInEqAAAAAELoO0IdM7ct1mokBpj_5C-QD318'}
                  ref={refCaptcha}
                  onChange={setRecaptchaValue}
                  onExpired={() => {
                    refCaptcha.current.reset(); // here
                  }}
                  theme="light"
                />
                <ButtonInput type="submit" value="Send Message" className="pointer animate radius8" onClick={handleSubmit} style={{ maxWidth: "220px" }} />
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
