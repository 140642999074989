import * as React from "react";

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={47} height={47} viewBox="0 0 26 40" {...props}>
      <g data-name="Group 101" transform="translate(-374 -21)">
        <rect data-name="Rectangle 16" width={19} height={8} rx={5} transform="translate(377.56 53)" fill="#f40051"/>
        <rect data-name="Rectangle 11" width={19} height={8} rx={5} transform="translate(377.56 21)" fill="#f2b300" />
        <rect data-name="Rectangle 15" width={19} height={8} rx={5} transform="translate(377.56 37)" fill="#7620ff" />
        <rect data-name="Rectangle 12" width={20} height={30} rx={10} transform="translate(356.1 20)" fill="#4cd5c5" />
        <rect data-name="Rectangle 17" width={20} height={30} rx={10} transform="translate(398.21 20)" fill="#4cd5c5" />
      </g>
    </svg>
  );
}

export default SvgComponent;
